import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      nisn: "",
      email: "",
      password: "",
      errors: {},
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard"); // push user to dashboard when they login
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      nisn: this.state.nisn,
      email: this.state.email,
      password: this.state.password,
    };
    this.props.loginUser(userData); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
  };

  render() {
    const { errors } = this.state;

    return (
      <div className="desktop">
        <img className="stars-backgrounds" alt="Stars background" src="image/stars-background.png" />
        <form noValidate onSubmit={this.onSubmit}>
      <h3>Masuk Akun</h3>
      <div className="form-input">
      <label htmlFor="nisn">NISN</label>
      <input
                  onChange={this.onChange}
                  value={this.state.nisn}
                  error={errors.nisn}
                  id="nisn"
                  type="number"
                  className={classnames("", {
                    invalid: errors.nisn || errors.nisnnotfound,
                  })}
                />
                <span className="red-text">
                  {errors.nisn}
                  {errors.nisnnotfound}
                </span>

      </div>
      
      <div className="form-input">
        <label htmlFor="email">Email</label>
      <input
                  onChange={this.onChange}
                  value={this.state.email}
                  error={errors.email}
                  id="email"
                  type="email"
                  className={classnames("", {
                    invalid: errors.email || errors.emailnotfound,
                  })}
                />
                <span className="red-text">
                  {errors.email}
                  {errors.emailnotfound}
                </span>           



      </div>
      
      
      <div className="form-input">
        <label htmlFor="password">Password</label>
      <input
                  onChange={this.onChange}
                  value={this.state.password}
                  error={errors.password}
                  id="password"
                  type="password"
                  className={classnames("", {
                    invalid: errors.password || errors.passwordincorrect,
                  })}
                />
                <span className="red-text">
                  {errors.password}
                  {errors.passwordincorrect}
                </span>           



      </div>
      
      
      
      
      <button type='submit'>Masuk</button>
      <p>Belum mempunyai akun? <Link to='/register'>Daftar Sekarang</Link>.</p>
    </form>
      </div>
      // <div className="container">
      //   <div style={{ marginTop: "4rem" }} className="row">
      //     <div className="col s8 offset-s2">
      //       <Link to="/" className="btn-flat waves-effect">
      //         <i className="material-icons left">keyboard_backspace</i> Back to
      //         home
      //       </Link>

      //       <div className="col s12" style={{ paddingLeft: "11.250px" }}>
      //         <h4>
      //           <b>Login</b> below
      //         </h4>
      //         <p className="grey-text text-darken-1">
      //           Don't have an account? <Link to="/register">Register</Link>
      //         </p>
      //       </div>

      //       <form noValidate onSubmit={this.onSubmit}>
      //         <div className="input-field col s12">
      //           <input
      //             onChange={this.onChange}
      //             value={this.state.nisn}
      //             error={errors.nisn}
      //             id="nisn"
      //             type="number"
      //             className={classnames("", {
      //               invalid: errors.nisn || errors.nisnnotfound,
      //             })}
      //           />
      //           <label htmlFor="nisn">nisn</label>
      //           <span className="red-text">
      //             {errors.nisn}
      //             {errors.nisnnotfound}
      //           </span>
      //         </div>
      //         <div className="input-field col s12">
      //           <input
      //             onChange={this.onChange}
      //             value={this.state.email}
      //             error={errors.email}
      //             id="email"
      //             type="email"
      //             className={classnames("", {
      //               invalid: errors.email || errors.emailnotfound,
      //             })}
      //           />
      //           <label htmlFor="email">Email</label>
      //           <span className="red-text">
      //             {errors.email}
      //             {errors.emailnotfound}
      //           </span>
      //         </div>

      //         <div className="input-field col s12">
      //           <input
      //             onChange={this.onChange}
      //             value={this.state.password}
      //             error={errors.password}
      //             id="password"
      //             type="password"
      //             className={classnames("", {
      //               invalid: errors.password || errors.passwordincorrect,
      //             })}
      //           />
      //           <label htmlFor="password">Password</label>
      //           <span className="red-text">
      //             {errors.password}
      //             {errors.passwordincorrect}
      //           </span>
      //         </div>

      //         <div className="col s12" style={{ paddingLeft: "11.250px" }}>
      //           <button
      //             style={{
      //               width: "150px",
      //               borderRadius: "3px",
      //               letterSpacing: "1.5px",
      //               marginTop: "1rem",
      //             }}
      //             type="submit"
      //             className="btn btn-large waves-effect waves-light hoverable blue accent-3"
      //           >
      //             Login
      //           </button>
      //         </div>
      //       </form>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(Login);
